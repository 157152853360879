angular
  .module('admin')
  .factory('ZoneEmploi', ZoneEmploi)

ZoneEmploi['$inject'] = ['ResourceFactory']
function ZoneEmploi (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/zone_emplois',
    name:  'zone_emploi',
    texts: {
      singular: "zone d'emploi",
      plural:   "zones d'emploi",
      gender:   'f'
    }
  })
}
