angular
  .module('admin')
  .factory('TerritoireIndustriel', TerritoireIndustriel)

TerritoireIndustriel['$inject'] = ['ResourceFactory']
function TerritoireIndustriel (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/territoire_industriels',
    name:  'territoire_industriel',
    texts: {
      singular: 'territoire industriel',
      plural:   'territoires industriels'
    }
  })
}
