angular
  .module('admin')
  .component('editCollectivite', {
    controller: Controller,
    template:   require('./edit_collectivite.template.pug')(),
    bindings:   {
      collectiviteId: '<'
    }
  })

Controller['$inject'] = [
  '$q', '$filter', '$mdDialog',
  'Collectivite', 'OptionsService', 'Autocompletion', 'RailsResourceForm'
]
function Controller (
  $q, $filter, $mdDialog,
  Collectivite, OptionsService, Autocompletion, RailsResourceForm
) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit              = onInit
  ctrl.applyCollectivite    = applyCollectivite
  ctrl.applyPackage         = applyPackage
  ctrl.applyProduct         = applyProduct
  ctrl.cancel               = cancel
  ctrl.selectTab            = selectTab
  ctrl.submit               = submit

  ctrl.autocompletions = {
    commune:     new Autocompletion('/api/autocomplete/communes'),
    epci:        new Autocompletion('/api/autocomplete/epcis'),
    departement: new Autocompletion('/api/autocomplete/departements')
  }

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const collectivite = new Collectivite({
      id:       ctrl.collectiviteId,
      products: []
    })

    $q.all({
      collectivite: (collectivite.id ? collectivite.get() : collectivite),
      options:      OptionsService.get('collectivites')
    }).then(function (results) {
      ctrl.collectivite = results.collectivite
      ctrl.options      = results.options

      delete ctrl.collectivite.files
      delete ctrl.collectivite.synthesis
      delete ctrl.collectivite.originalData

      if (ctrl.collectivite.id) {
        ctrl.submitReady = true
      } else {
        ctrl.collectivite.products.check('serveur')
        ctrl.collectivite.package = 'essentiel'

        // Uncomment this, if you want AtelierFiscal to be check
        // by default
        // applyPackage('essentiel')
      }

      ctrl.ready = true
    })
  }

  // Public functions
  // -------------------------------------------------------------------------
  const codcollKeys = {
    commune:     'idcom',
    epci:        'idepci',
    departement: 'iddep'
  }

  function applyCollectivite (item) {
    const codcollKey = codcollKeys[ctrl.collectivite.typec]

    if (item) {
      ctrl.collectivite.codcoll = item[codcollKey]
      ctrl.collectivite.libcoll = item.nom_insee

      if (ctrl.collectivite.typec === 'epci') {
        ctrl.collectivite.siren = $filter('siren')(item.idepci)
      }
    }
  }

  function applyPackage (value) {
    const products = ctrl.options.packages[value]

    ctrl.collectivite.package = value

    if (value && products) {
      angular.forEach(products, (product) => {
        ctrl.collectivite.products.check(product)
      })

      angular.forEach(ctrl.options.packages, (others, key) => {
        if (key !== value) {
          angular.forEach(others, (other) => {
            if (!products.includes(other)) {
              ctrl.collectivite.products.uncheck(other)
            }
          })
        }
      })
    }
  }

  function applyProduct (name) {
    const method = ctrl.collectivite.products.includes(name) ? 'check' : 'uncheck'

    angular.forEach(ctrl.options.products, (product) => {
      if (product[0].indexOf(name + '::') === 0) {
        ctrl.collectivite.products[method](product[0])
      }
    })
  }

  function cancel () {
    $mdDialog.cancel()
  }

  function selectTab (index) {
    ctrl.tab = index
    if (ctrl.tab >= 4) ctrl.submitReady = true
  }

  function submit () {
    new RailsResourceForm({
      formController: ctrl.form,
      resource:       ctrl.collectivite
    }).submit()
      .then($mdDialog.hide)
      .catch(function () {
        if (ctrl.form.codcoll.$invalid || ctrl.form.libcoll.$invalid) {
          ctrl.tab = 0
        }
      })
  }
}
