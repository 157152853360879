require('./config/labelize.config.js')
require('./services/region.factory.js')
require('./services/departement.factory.js')
require('./services/epci.factory.js')
require('./services/epci_labels.constant.js')
require('./services/canton.factory.js')
require('./services/commune.factory.js')
require('./services/greffe.factory.js')
require('./services/iris.factory.js')
require('./services/urssaf.factory.js')
require('./services/urssaf_site.factory.js')
require('./services/zone_emploi.factory.js')
require('./services/bassin_emploi.factory.js')
require('./services/bassin_de_vie.factory.js')
require('./services/coeur_de_ville.factory.js')
require('./services/territoire_industriel.factory.js')
require('./services/aire_urbaine.factory.js')
require('./components/territoires_dashboard.component.js')
require('./components/modals/edit_territoire.component.js')
