angular
  .module('admin')
  .component('usersDashboard', {
    controller: Controller,
    template:   require('./users_dashboard.template.pug')()
  })

Controller['$inject'] = ['$location', 'User', 'dialogComponent']
function Controller ($location, User, dialogComponent) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit              = onInit
  ctrl.release              = release
  ctrl.create               = create
  ctrl.update               = update
  ctrl.signAs               = signAs
  ctrl.removeAll            = removeAll
  ctrl.updateBuilderAccount = updateBuilderAccount

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const params = $location.search()

    User.preload(params).then((users) => {
      ctrl.users = users
      ctrl.ready = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function release () {
    $location.search({})
  }

  function create (event) {
    dialogComponent.show({
      template:    '<edit-user></edit-user>',
      ariaLabel:   'Inviter un utilisateur',
      targetEvent: event
    }).then(reload)
  }

  function update (user, event) {
    dialogComponent.show({
      template:    '<edit-user user-id="$dialog.userId"></edit-user>',
      ariaLabel:   'Editer un utilisateur',
      targetEvent: event,
      locals:      {
        userId: user.id
      }
    }).then(reload)
  }

  function signAs (user, event) {
    dialogComponent.show({
      template:    '<sign-as-user user="$dialog.user"></sign-as-user>',
      ariaLabel:   'Se connecter avec le compte de ' + user.name,
      targetEvent: event,
      locals:      {
        user: user
      }
    })
  }

  function removeAll (event) {
    dialogComponent.show({
      template:    '<remove-users selection="$dialog.selection"></remove-users>',
      ariaLabel:   'Supprimer les utilisateurs sélectionnés',
      targetEvent: event,
      locals:      {
        selection: ctrl.users.$selection
      }
    }).then(reload)
  }

  function updateBuilderAccount (user, event) {
    dialogComponent.show({
      template:    '<validate-builder-account user-id="$dialog.userId"></validate-builder-account>',
      ariaLabel:   "Valider le compte Builder d'un utilisateur",
      targetEvent: event,
      locals:      {
        userId: user.id
      }
    })
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function reload () {
    ctrl.users.reload()
  }
}
