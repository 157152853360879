angular
  .module('admin')
  .factory('Workbook', Workbook)

Workbook['$inject'] = ['ResourceFactory']
function Workbook (ResourceFactory) {
  return new ResourceFactory({
    url:      '/api/workbooks',
    name:     'workbook',
    services: ['sort', 'selection', 'search'],
    texts:    {
      singular: 'analyse',
      plural:   'analyses',
      gender:   'f'
    }
  })
}
