angular
  .module('admin')
  .factory('UrssafSite', UrssafSite)

UrssafSite['$inject'] = ['ResourceFactory']
function UrssafSite (ResourceFactory) {
  return new ResourceFactory({
    url:        '/api/ta/urssaf_sites',
    name:       'urssaf_site',
    pluralName: 'urssaf_sites',
    texts:      {
      singular: 'site URSSAF',
      plural:   'sites URSSAF',
      gender:   'm'
    }
  })
}
