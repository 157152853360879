angular
  .module('admin')
  .factory('Contract', Contract)

Contract['$inject'] = ['ResourceFactory']
function Contract (ResourceFactory) {
  return new ResourceFactory({
    url:  '/api/contracts',
    name: 'contract'
  })
}
