angular
  .module('admin')
  .component('editImports', {
    controller: Controller,
    template:   require('./edit_imports.template.pug')(),
    bindings:   {
      selection: '='
    }
  })

Controller['$inject'] = ['$http', '$mdDialog', 'OptionsService']
function Controller ($http, $mdDialog, OptionsService) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit  = onInit
  ctrl.cancel   = cancel
  ctrl.submit   = submit

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    OptionsService.get('imports').then(function (options) {
      ctrl.options = options
      ctrl.ready   = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function submit () {
    $http({
      url:    '/api/requests',
      method: 'PUT',
      data:   {
        ids:     ctrl.selection.getIds(),
        request: ctrl.requests || {}
      }
    }).then($mdDialog.hide)
  }
}
