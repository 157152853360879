angular
  .module('admin')
  .factory('Greffe', Greffe)

Greffe['$inject'] = ['ResourceFactory']
function Greffe (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/greffes',
    name:  'greffe',
    texts: {
      singular: 'greffe',
      plural:   'greffe',
      gender:   'm'
    }
  })
}
