angular
  .module('admin')
  .component('editImport', {
    controller: Controller,
    template:   require('./edit_import.template.pug')(),
    bindings:   {
      requestId: '<'
    }
  })

Controller['$inject'] = ['$q', '$mdDialog', '$http', 'ImportRequest', 'OptionsService', 'RailsResourceForm']
function Controller ($q, $mdDialog, $http, ImportRequest, OptionsService, RailsResourceForm) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.cancel  = cancel
  ctrl.revert  = revert
  ctrl.submit  = submit

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const request = new ImportRequest({ id: ctrl.requestId }).get()

    $q.all({
      request: request,
      options: OptionsService.get('imports')
    }).then(function (results) {
      ctrl.request = results.request
      ctrl.options = results.options
      ctrl.ready   = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function revert () {
    $http
      .put('/api/requests/revert', { ids: [ctrl.request.id] })
      .then($mdDialog.hide)
  }

  function submit () {
    new RailsResourceForm({
      formController: ctrl.form,
      resource:       ctrl.request
    }).submit()
      .then($mdDialog.hide)
  }
}
