angular.module('admin', [
  'atelier',
  'atelier.userAccounts',
  'app.admin'
])

require('./components/collectivites')
require('./components/imports')
require('./components/territoires')
require('./components/users')
require('./components/workbooks')
