angular
  .module('admin')
  .component('signAsUser', {
    controller: Controller,
    template:   require('./sign_as_user.template.pug')(),
    bindings:   {
      user: '<'
    }
  })

Controller['$inject'] = ['$http', '$mdDialog', '$window']
function Controller ($http, $mdDialog, $window) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.cancel  = cancel
  ctrl.confirm = confirm

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    $http
      .get('/api/users/' + ctrl.user.id + '/active')
      .then(
        function () {
          ctrl.user.active = true
          ctrl.ready       = true
        },
        function (reponse) {
          ctrl.user.active = false
          ctrl.message     = reponse.data.message
          ctrl.ready       = true
        }
      )
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function confirm () {
    $http
      .post('/api/users/' + ctrl.user.id + '/sign')
      .then(function (response) {
        $window.location = response.headers('Location')
      })
  }
}
