angular
  .module('admin')
  .factory('Urssaf', Urssaf)

Urssaf['$inject'] = ['ResourceFactory']
function Urssaf (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/urssafs',
    name:  'urssaf',
    texts: {
      singular: 'URSSAF',
      plural:   'URSSAF',
      gender:   'm'
    }
  })
}
