angular
  .module('admin')
  .factory('AireUrbaine', AireUrbaine)

AireUrbaine['$inject'] = ['ResourceFactory']
function AireUrbaine (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/aire_urbaines',
    name:  'aire_urbaine',
    texts: {
      singular: 'aire urbaine',
      plural:   'aires urbaines',
      gender:   'f'
    }
  })
}
