angular
  .module('admin')
  .component('editContract', {
    controller: Controller,
    template:   require('./edit_contract.template.pug')(),
    bindings:   {
      contractId:     '<',
      collectiviteId: '<'
    }
  })

Controller['$inject'] = [
  '$q', '$mdDialog',
  'Contract', 'FileUploader', 'CSRF', 'RailsResourceForm', 'OptionsService']
function Controller (
  $q, $mdDialog,
  Contract, FileUploader, CSRF, RailsResourceForm, OptionsService
) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit        = onInit
  ctrl.cancel         = cancel
  ctrl.submit         = submit
  ctrl.removeDocument = removeDocument

  ctrl.uploader = new FileUploader({
    url:               '/api/contracts/documents',
    autoUpload:        true,
    formData:          [CSRF],
    removeAfterUpload: true,
    onSuccessItem:     addDocument
  })

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const contract = new Contract({
      id:              ctrl.contractId,
      collectivite_id: ctrl.collectiviteId
    })

    $q.all({
      contract: (contract.id ? contract.get() : contract),
      options:  OptionsService.get('contracts')
    }).then(function (results) {
      ctrl.contract = results.contract
      ctrl.options  = results.options
      ctrl.ready    = true
    })
  }

  // Public functions
  // -------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function submit () {
    new RailsResourceForm({
      formController: ctrl.form,
      resource:       ctrl.contract,
      url:            '/api/contracts'
    }).submit()
      .then($mdDialog.hide)
  }

  function addDocument (_item, response) {
    ctrl.contract.documents_attributes.push(response.document)
  }

  function removeDocument (document) {
    document._destroy = true
  }
}
