angular
  .module('admin')
  .component('importsDashboard', {
    controller: Controller,
    template:   require('./imports_dashboard.template.pug')()
  })

Controller['$inject'] = ['ImportRequest', 'dialogComponent']
function Controller (ImportRequest, dialogComponent) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit    = onInit
  ctrl.reload     = reload
  ctrl.edit       = edit
  ctrl.editAll    = editAll
  ctrl.enqueueAll = enqueueAll
  ctrl.removeAll  = removeAll
  ctrl.revertAll  = revertAll
  ctrl.suspendAll = suspendAll

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    ImportRequest.preload().then((requests) => {
      ctrl.requests = requests
      ctrl.ready    = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function reload () {
    ctrl.requests.reload()
  }

  function edit (request, event) {
    dialogComponent.show({
      template:    '<edit-import request-id="$dialog.requestId">',
      ariaLabel:   "Compléter les informations du fichier d'import",
      targetEvent: event,
      locals:      {
        requestId: request.id
      }
    }).then(reload)
  }

  function editAll (event) {
    const selection = ctrl.requests.$selection

    if (selection.size === 1) {
      edit(selection.values[0], event)
      return
    }

    dialogComponent.show({
      template:    '<edit-imports selection="$dialog.selection">',
      ariaLabel:   'Compléter les informations des fichiers sélectionnés',
      targetEvent: event,
      locals:      {
        selection: selection
      }
    }).then(reload)
  }

  function enqueueAll (event) {
    dialogComponent.show({
      template:    '<enqueue-imports selection="$dialog.selection">',
      ariaLabel:   "Relancer l'import des fichiers sélectionnés",
      targetEvent: event,
      locals:      {
        selection: ctrl.requests.$selection
      }
    }).then(reload)
  }

  function removeAll (event) {
    dialogComponent.show({
      template:    '<remove-imports selection="$dialog.selection">',
      ariaLabel:   'Supprimer les fichiers sélectionnés',
      targetEvent: event,
      locals:      {
        selection: ctrl.requests.$selection
      }
    }).then(reload)
  }

  function revertAll (event) {
    dialogComponent.show({
      template:    '<revert-imports selection="$dialog.selection">',
      ariaLabel:   'Nettoyer les données importées des fichiers sélectionnés',
      targetEvent: event,
      locals:      {
        selection: ctrl.requests.$selection
      }
    }).then(reload)
  }

  function suspendAll (event) {
    dialogComponent.show({
      template:    '<suspend-imports selection="$dialog.selection">',
      ariaLabel:   "Suspendre l'import des fichiers sélectionnés",
      targetEvent: event,
      locals:      {
        selection: ctrl.requests.$selection
      }
    }).then(reload)
  }
}
