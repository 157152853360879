angular
  .module('admin')
  .component('territoiresDashboard', {
    controller: Controller,
    template:   require('./territoires_dashboard.template.pug')(),
    bindings:   {
      modelName: '@model'
    }
  })

Controller['$inject'] = ['$injector', '$location', 'dialogComponent']
function Controller ($injector, $location, dialogComponent) {
  const ctrl = this

  // Bindable members
  // -------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.release = release
  ctrl.update  = update

  // Hooks
  // -------------------------------------------------------------------------
  function onInit () {
    ctrl.modelFactory = $injector.get(ctrl.modelName)
    ctrl.modelFactory.$collection.$search.value = $location.search().filter

    ctrl.modelFactory
      .preload()
      .then(function (territoires) {
        ctrl.territoires = territoires
        ctrl.ready       = true
      })
  }

  // Public functions
  // -------------------------------------------------------------------------
  function release () {
    $location.search({})
  }

  function update (territoire, event) {
    dialogComponent.show({
      template:    '<edit-territoire model="{{ $dialog.model }}" territoire-id="$dialog.territoireId">',
      ariaLabel:   'Editer un territoire',
      targetEvent: event,
      locals:      {
        model:        ctrl.modelName,
        territoireId: territoire.id
      }
    }).then(reload)
  }

  // Private functions
  // -------------------------------------------------------------------------
  function reload () {
    ctrl.territoires.reload()
  }
}
