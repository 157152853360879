angular
  .module('admin')
  .factory('User', User)

User['$inject'] = ['ResourceFactory']
function User (ResourceFactory) {
  return new ResourceFactory({
    url:        '/api/users',
    name:       'user',
    serializer: function () {
      this.serializeWith('permissions', 'ChoicesSerializer')
      this.resource('company', 'Company')
    },
    texts: {
      singular: 'utilisateur',
      plural:   'utilisateurs',
      gender:   'm'
    }
  })
}
