angular
  .module('admin')
  .factory('Epci', Epci)

Epci['$inject'] = ['ResourceFactory']
function Epci (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/epcis',
    name:  'epci',
    texts: {
      singular: 'EPCI',
      plural:   'EPCI',
      gender:   'm'
    }
  })
}
