angular
  .module('admin')
  .factory('Canton', Canton)

Canton['$inject'] = ['ResourceFactory']
function Canton (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/cantons',
    name:  'canton',
    texts: {
      singular: 'canton',
      plural:   'cantons',
      gender:   'm'
    }
  })
}
