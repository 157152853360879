angular
  .module('admin')
  .factory('Commune', Commune)

Commune['$inject'] = ['ResourceFactory']
function Commune (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/communes',
    name:  'commune',
    texts: {
      singular: 'commune',
      plural:   'communes',
      gender:   'f'
    }
  })
}
