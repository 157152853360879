angular
  .module('admin')
  .component('removeWorkbooks', {
    controller: Controller,
    template:   require('./remove_workbooks.template.pug')(),
    bindings:   {
      selection: '<'
    }
  })

Controller['$inject'] = ['$http', '$mdDialog']
function Controller ($http, $mdDialog) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.cancel = cancel
  ctrl.submit = submit

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function submit () {
    $http({
      url:    '/api/workbooks',
      method: 'DELETE',
      data:   { ids: ctrl.selection.getIds() }
    }).then($mdDialog.hide)
  }
}
