angular
  .module('admin')
  .factory('Iris', Iris)

Iris['$inject'] = ['ResourceFactory']
function Iris (ResourceFactory) {
  return new ResourceFactory({
    url:        '/api/ta/irises',
    name:       'iris',
    pluralName: 'irises',
    texts:      {
      singular: 'IRIS',
      plural:   'IRIS',
      gender:   'm'
    }
  })
}
