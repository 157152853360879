angular
  .module('admin')
  .component('workbooksDashboard', {
    controller: Controller,
    template:   require('./workbooks_dashboard.template.pug')()
  })

Controller['$inject'] = ['Workbook', 'OptionsService', 'dialogComponent', '$filter', '$http']
function Controller (Workbook, OptionsService, dialogComponent, $filter, $http) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit       = onInit
  ctrl.create        = create
  ctrl.update        = update
  ctrl.removeAll     = removeAll
  ctrl.startSorting  = startSorting
  ctrl.cancelSorting = cancelSorting
  ctrl.submitSorting = submitSorting

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    Workbook.preload().then((workbooks) => {
      ctrl.workbooks = workbooks
      ctrl.ready     = true
    })

    OptionsService.get('workbooks').then(function (options) {
      const remoteWorkbooks = {}

      options.workbooks.forEach(function (array) {
        remoteWorkbooks[array[0]] = array[1]
      })

      ctrl.remoteWorkbooks = remoteWorkbooks
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function create (event) {
    dialogComponent.show({
      template:    '<edit-workbook></edit-workbook>',
      ariaLabel:   'Nouveau tableau',
      targetEvent: event
    }).then(reload)
  }

  function update (workbook, event) {
    dialogComponent.show({
      template:    '<edit-workbook workbook-id="$dialog.workbookId"></edit-workbook>',
      ariaLabel:   'Editer un tableau',
      targetEvent: event,
      locals:      {
        workbookId: workbook.id
      }
    }).then(reload)
  }

  function removeAll (event) {
    dialogComponent.show({
      template:    '<remove-workbooks selection="$dialog.selection"></remove-workbooks>',
      ariaLabel:   'Supprimer les tableaux sélectionnés',
      targetEvent: event,
      locals:      {
        selection: ctrl.workbooks.$selection
      }
    }).then(reload)
  }

  function startSorting () {
    ctrl.sorting  = true
    ctrl.sortable = $filter('orderBy')(ctrl.workbooks, 'sequence')
  }

  function cancelSorting () {
    ctrl.sorting = false
  }

  function submitSorting () {
    $http({
      url:    '/api/workbooks',
      method: 'PUT',
      data:   { workbooks: getSortableParams() }
    }).then(() => {
      reload()
      ctrl.sorting = false
    })
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function reload () {
    ctrl.workbooks.reload()
  }

  function getSortableParams () {
    return {
      order: ctrl.sortable.map((item) => item.id)
    }
  }
}
