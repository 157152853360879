angular
  .module('admin')
  .component('editUser', {
    controller: Controller,
    template:   require('./edit_user.template.pug')(),
    bindings:   {
      userId: '<'
    }
  })

Controller['$inject'] = [
  '$q', '$mdDialog',
  'User', 'OptionsService', 'FileUploader', 'CSRF', 'RailsResourceForm',
  'dialogComponent'
]
function Controller (
  $q, $mdDialog,
  User, OptionsService, FileUploader, CSRF, RailsResourceForm,
  dialogComponent
) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit              = onInit
  ctrl.cancel               = cancel
  ctrl.selectTab            = selectTab
  ctrl.submit               = submit
  ctrl.updateBuilderAccount = updateBuilderAccount

  ctrl.uploader  = new FileUploader({
    url:           '/api/photos',
    autoUpload:    true,
    formData:      [angular.extend({ version: 'avatar' }, CSRF)],
    onSuccessItem: addPhoto
  })

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const user = new User({
      id:          ctrl.userId,
      permissions: []
    })

    $q.all({
      user:    (user.id ? user.get() : user),
      options: OptionsService.get('users')
    }).then(function (results) {
      ctrl.user        = results.user
      ctrl.options     = results.options
      ctrl.submitReady = !!ctrl.user.id
      ctrl.ready       = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function selectTab (index) {
    ctrl.tab = index
    if (ctrl.tab >= 2) ctrl.submitReady = true
  }

  function submit () {
    new RailsResourceForm({
      formController: ctrl.form,
      resource:       ctrl.user
    }).submit()
      .then($mdDialog.hide)
      .catch(function () {
        ctrl.tab = 0
      })
  }

  function updateBuilderAccount (event) {
    dialogComponent.show({
      template:  '<validate-builder-account user-id="$dialog.userId"></validate-builder-account>',
      ariaLabel: "Valider le compte Builder d'un utilisateur",
      multiple:  true,
      locals:    {
        userId: ctrl.user.id
      }
    })
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function addPhoto (item, response) {
    ctrl.user.photo_attributes = response.photo
  }
}
