angular
  .module('admin')
  .factory('Region', Region)

Region['$inject'] = ['ResourceFactory']
function Region (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/regions',
    name:  'region',
    texts: {
      singular: 'région',
      plural:   'régions',
      gender:   'f'
    }
  })
}
