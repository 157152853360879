import { natures, fiscalites } from '../services/epci_labels.constant.js'

angular
  .module('admin')
  .config(labelizeConfig)

labelizeConfig['$inject'] = ['labelizeProvider']
function labelizeConfig (labelizeProvider) {
  labelizeProvider.extend({
    epci: {
      nature:    natures,
      fiscalite: fiscalites
    }
  })
}
