angular
  .module('admin')
  .factory('BassinDeVie', BassinDeVie)

BassinDeVie['$inject'] = ['ResourceFactory']
function BassinDeVie (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/bassin_de_vies',
    name:  'bassin_de_vie',
    texts: {
      singular: 'bassin de vie',
      plural:   'bassins de vie'
    }
  })
}
