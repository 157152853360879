angular
  .module('admin')
  .component('collectivitesDashboard', {
    controller: Controller,
    template:   require('./collectivites_dashboard.template.pug')()
  })

Controller['$inject'] = [
  '$q', '$location', '$http',
  'Collectivite', 'dialogComponent', 'OptionsService',
  'confirm'
]
function Controller (
  $q, $location, $http,
  Collectivite, dialogComponent, OptionsService,
  confirm
) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit              = onInit
  ctrl.release              = release
  ctrl.isRestrictedById     = isRestrictedById
  ctrl.applyFilters         = applyFilters
  ctrl.create               = create
  ctrl.update               = update
  ctrl.removeAll            = removeAll
  ctrl.addContract          = addContract
  ctrl.updateContract       = updateContract
  ctrl.archiveContract      = archiveContract

  ctrl.filters = {
    type:                 'all',
    contractState:        'all',
    contractkind:         'all',
    contractBillingMonth: 'all'
  }

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const params = $location.search()

    $q.all([
      Collectivite.preload(params),
      OptionsService.get('collectivites')
    ]).then(function (results) {
      ctrl.collectivites = results[0]
      ctrl.options       = results[1]
      ctrl.ready         = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function release () {
    $location.search({})
  }

  function isRestrictedById () {
    return ctrl.collectivites.restricted && 'id' in ctrl.collectivites.restrictedParams
  }

  function applyFilters () {
    ctrl.ready = false
    ctrl.collectivites.restrict(ctrl.filters).then(() => {
      ctrl.ready = true
    })
  }

  function create (event) {
    dialogComponent.show({
      template:    '<edit-collectivite></edit-collectivite>',
      ariaLabel:   'Créer une nouvelle collectivité',
      targetEvent: event
    }).then(reload)
  }

  function update (collectivite, event) {
    dialogComponent.show({
      template:    '<edit-collectivite collectivite-id="$dialog.collectiviteId"></edit-collectivite>',
      ariaLabel:   'Editer une collectivité',
      targetEvent: event,
      locals:      {
        collectiviteId: collectivite.id
      }
    }).then(reload)
  }

  function removeAll (event) {
    confirm(
      'Supprimer les collectivités',
      ` <p><b>Attention, cette opération est irréversible.</b></p>
        <p>Les collectivités sélectionnées seront définitivement supprimées et toutes leurs données seront supprimées.</p>
        <p>Etes-vous sûr de vouloir supprimer les collectivités sélectionnées ?</p>`,
      event
    ).then(() => {
      $http({
        url:    '/api/collectivites',
        method: 'DELETE',
        data:   { ids: ctrl.collectivites.$selection.getIds() }
      }).then(reload)
    })
  }

  function addContract (collectivite, event) {
    dialogComponent.show({
      template:    '<edit-contract collectivite-id="$dialog.collectiviteId"></edit-contract>',
      ariaLabel:   'Ajouter un contrat à une collectivité',
      targetEvent: event,
      locals:      {
        collectiviteId: collectivite.id
      }
    }).then(reload)
  }

  function updateContract (contract, event) {
    dialogComponent.show({
      template:    '<edit-contract contract-id="$dialog.contractId"></edit-contract>',
      ariaLabel:   'Modifier un contrat',
      targetEvent: event,
      locals:      {
        contractId: contract.id
      }
    }).then(reload)
  }

  function archiveContract (contract, event) {
    confirm(
      'Archiver le contrat',
      ` <p><b>Attention, cette opération est irréversible.</b></p>
        <p>Etes-vous sûr de vouloir archiver ce contrat ?</p>`,
      event
    ).then(() => {
      $http({
        url:    '/api/contracts/' + contract.id + '/archive',
        method: 'POST'
      }).then(reload)
    })
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function reload () {
    ctrl.collectivites.reload()
  }
}
