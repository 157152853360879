export const natures = {
  CA:    "Communauté d'agglomération",
  CC:    'Communauté de communes',
  CU:    'Communautés urbaines',
  METRO: 'Métropoles',
  EPT:   'Établissement public territorial (Grand Paris)',
  SA:    "Syndicat d'agglomération"
}

export const fiscalites = {
  'FA':  'Fiscalité additionnelle',
  'FPU': 'Fiscalité professionnelle unique',
  'FPZ': 'Fiscalité de zone',
  'TPU': 'Taxe professionnelle unique',
  '4TX': '4 Taxes'
}

angular
  .module('admin')
  .constant('EpciLabels', {
    nature:    natures,
    fiscalite: fiscalites
  })
