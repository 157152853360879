angular
  .module('admin')
  .component('validateBuilderAccount', {
    controller: Controller,
    template:   require('./validate_builder_account.template.pug')(),
    bindings:   {
      userId: '<'
    }
  })

Controller['$inject'] = ['$http', '$mdDialog']
function Controller ($http, $mdDialog) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit  = onInit
  ctrl.cancel   = cancel
  ctrl.validate = validate

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    $http
      .get('/api/users/' + ctrl.userId + '/builder_account')
      .then(function (response) {
        ctrl.account = response.data.builder_account
        ctrl.ready   = true
      })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function validate () {
    $http.put('/api/users/' + ctrl.userId + '/builder_account', {
      builder_account: ctrl.account
    }).then($mdDialog.hide)
  }
}
