angular
  .module('admin')
  .component('editTerritoire', {
    controller: Controller,
    template:   require('./edit_territoire.template.pug')(),
    bindings:   {
      modelName:    '@model',
      territoireId: '<'
    }
  })

Controller['$inject'] = ['$q', '$mdDialog', '$injector', 'RailsResourceForm']
function Controller ($q, $mdDialog, $injector, RailsResourceForm) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit = onInit
  ctrl.cancel  = cancel
  ctrl.submit  = submit

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const ModelFactory = $injector.get(ctrl.modelName)
    const territoire   = new ModelFactory({ id: ctrl.territoireId })

    $q.when(
      territoire.id ? territoire.get() : territoire
    ).then((result) => {
      ctrl.territoire = result
      ctrl.ready      = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function submit () {
    new RailsResourceForm({
      formController: ctrl.form,
      resource:       ctrl.territoire
    }).submit()
      .then($mdDialog.hide)
  }
}
