angular
  .module('admin')
  .component('editWorkbook', {
    controller: Controller,
    template:   require('./edit_workbook.template.pug')(),
    bindings:   {
      workbookId: '<'
    }
  })

Controller['$inject'] = [
  '$q', '$mdDialog',
  'Workbook', 'OptionsService', 'FileUploader', 'CSRF', 'RailsResourceForm'
]
function Controller (
  $q, $mdDialog,
  Workbook, OptionsService, FileUploader, CSRF, RailsResourceForm
) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit       = onInit
  ctrl.cancel        = cancel
  ctrl.selectTab     = selectTab
  ctrl.setDefaults   = setDefaults
  ctrl.removePicture = removePicture
  ctrl.submit        = submit
  ctrl.transformChip = transformChip
  ctrl.updateChips   = updateChips

  ctrl.uploader  = new FileUploader({
    url:           '/api/workbooks/pictures',
    autoUpload:    true,
    formData:      [CSRF],
    onSuccessItem: addPicture
  })

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
    const workbook = new Workbook({
      id:          ctrl.workbookId,
      permissions: []
    })

    $q.all({
      workbook: (workbook.id ? workbook.get() : workbook),
      options:  OptionsService.get('workbooks')
    }).then(function (results) {
      ctrl.workbook    = results.workbook
      ctrl.options     = results.options
      ctrl.submitReady = !!ctrl.workbook.id

      const chips = []
      const ids   = ctrl.workbook.collectivite_ids

      if (ids && ids.length) {
        ids.forEach((id) => {
          const item = ctrl.options.collectivites.find((datum) => datum[0] === id)
          if (item) chips.push(item)
        })
      }

      ctrl.chips = chips
      ctrl.ready = true
    })
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function selectTab (index) {
    ctrl.tab = index
    if (ctrl.tab >= 2) ctrl.submitReady = true
  }

  function setDefaults () {
    const id   = ctrl.workbook.remote_workbook_id
    const item = ctrl.options.workbooks.find((wo) => wo[0] === id)

    if (item) ctrl.workbook.title = item[1]
  }

  function removePicture () {
    ctrl.workbook.picture        = null
    ctrl.workbook.picture_cache  = null
    ctrl.workbook.remove_picture = true
  }

  function submit () {
    new RailsResourceForm({
      formController: ctrl.form,
      resource:       ctrl.workbook
    }).submit()
      .then($mdDialog.hide)
      .catch(() => {
        ctrl.tab = 0
      })
  }

  function transformChip (chip) {
    return chip
  }

  function updateChips () {
    const ids = ctrl.chips.map((chip) => chip[0])
    ctrl.workbook.collectivite_ids = ids
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function addPicture (item, response) {
    ctrl.workbook.picture        = response.picture
    ctrl.workbook.picture_cache  = response.picture.cache_name
    ctrl.workbook.remove_picture = false
  }
}
