angular
  .module('admin')
  .factory('Departement', Departement)

Departement['$inject'] = ['ResourceFactory']
function Departement (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/departements',
    name:  'departement',
    texts: {
      singular: 'département',
      plural:   'départements',
      gender:   'm'
    }
  })
}
