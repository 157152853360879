angular
  .module('admin')
  .factory('BassinEmploi', BassinEmploi)

BassinEmploi['$inject'] = ['ResourceFactory']
function BassinEmploi (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/bassin_emplois',
    name:  'bassin_emploi',
    texts: {
      singular: "bassin d'emploi",
      plural:   "bassins d'emploi"
    }
  })
}
