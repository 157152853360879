angular
  .module('admin')
  .component('suspendImports', {
    controller: Controller,
    template:   require('./suspend_imports.template.pug')(),
    bindings:   {
      selection: '='
    }
  })

Controller['$inject'] = ['$http', '$mdDialog']
function Controller ($http, $mdDialog) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.cancel = cancel
  ctrl.submit = submit

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function submit () {
    $http({
      url:    '/api/requests/suspend',
      method: 'PUT',
      data:   { ids: ctrl.selection.getIds() }
    }).then($mdDialog.hide)
  }
}
