angular
  .module('admin')
  .config(config)

config['$inject'] = ['labelizeProvider']
function config (labelizeProvider) {
  labelizeProvider.add('imports', {
    codfichiers: {
      CBT:     'Cadastre bâti',
      CNB:     'Cadastre non-Bâti',
      CPR:     'Cadastre propriétaires',
      EDIGEO:  'EDIGEO',
      RTF:     'Taxe foncière',
      RTH:     "Taxe d'habitation",
      THC:     "Occupation des locaux d'habitation (Nominatif TH Format 3)",
      THB:     "Vacance des locaux d'habitation (1767 bis)",
      RLCFE:   'Locaux professionnels non imposés à la CFE',
      CFE:     'Cotisation foncière des entreprises',
      CVAE:    'Cotisation sur la valeur ajoutée des entreprises',
      TASCOM:  'Taxe sur les surfaces commerciales',
      VM:      'Versement mobilité',
      VT:      'Versement transport',
      L41CB:   'Liste 41 CBD',
      L41HB:   'Liste 41 HAB',
      PDL:     'Propriétés divisées en lots',
      LOC:     'Propriétés divisées en lots - Locaux',
      LOCPRO:  'Locaux professionnels',
      EP28UR:  'Suivi des encaissements VT URSSAF',
      EP28AC:  'Suivi des encaissements VT ACOSS',
      EP28AC2: 'Suivi des encaissements VT ACOSS (2022+)',
      EP28ACH: "Reprise d'historique des encaissements VT ACOSS"
    }
  })
}
