angular
  .module('admin')
  .factory('CoeurDeVille', CoeurDeVille)

CoeurDeVille['$inject'] = ['ResourceFactory']
function CoeurDeVille (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/ta/coeur_de_villes',
    name:  'coeur_de_ville',
    texts: {
      singular: 'coeur de ville',
      plural:   'coeurs de ville'
    }
  })
}
