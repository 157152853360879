angular
  .module('admin')
  .factory('ImportRequest', ImportRequest)

ImportRequest['$inject'] = ['ResourceFactory']
function ImportRequest (ResourceFactory) {
  return new ResourceFactory({
    url:   '/api/requests',
    name:  'request',
    texts: {
      singular: "demande d'import",
      plural:   "demandes d'import",
      gender:   'f'
    }
  })
}
