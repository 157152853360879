angular
  .module('admin')
  .factory('Collectivite', Collectivite)

Collectivite['$inject'] = ['ResourceFactory']
function Collectivite (ResourceFactory) {
  return new ResourceFactory({
    url:        '/api/collectivites',
    name:       'collectivite',
    serializer: function () {
      this.setDefault('typec', 'commune')
      this.setDefault('products', [])
      this.serializeWith('products', 'ChoicesSerializer')
      this.resource('contracts', 'Contract')
    },
    texts: {
      singular: 'collectivité',
      plural:   'collectivités',
      gender:   'f'
    }
  })
}
